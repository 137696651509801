@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&display=swap');

.form__inputReduzido {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  padding: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  margin: 10px 0;
  position: relative;
  outline: none;
}

.form__inputReduzido:disabled {
  width: 100%;
  font-family: "Montserrat", sans-serif;

  padding: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  opacity: 0.3;
}
.form__containerReduzido {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: baseline;
  width: 100%;
  max-width: 750px;
}
@media only screen and (max-width: 450px) {
  .form__containerReduzido {
    grid-template-columns: 1fr;
  }
}
.form_backgroundColor {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3em 20px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.button__perfilReduzido {
  font-family: "Montserrat", sans-serif;
  margin: 20px auto;
  width: 100%;
  font-weight: 700;
  padding: 3px;
  border-radius: 20px;
  background-color: #f18e17;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: none;
  color: #fff;
  cursor: pointer;
  height: 2em;
  max-width: 400px;
}
.button__perfilReduzido:hover {
  opacity: 0.5;
}
.button__perfilReduzido:active {
  opacity: 1;
}
.body__conteinerReduzido {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  margin-top: -20px;
  background-position: 50%;
  overflow-x: auto;
  text-align: center;
  display: flex;
}
.form__perfilLogo {
  margin-top: -2em;
  max-width: 10em;
}
.button__perfilReduzido-link {
  color: white;
  font-weight: bold;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  outline: none;
}
.button__perfilReduzido-link:hover {
  opacity: 0.5;
}
.button__perfilReduzido-link:active {
  opacity: 1;
}
.perfil_listaProdutos {
  margin: 0 !important;
}
.perfil_listaProdutos li {
  list-style: none;
}

.perfil_listaProdutos {
  text-align: left;
  margin-left: 30px;
}
.form__popup h3 {
  margin: 10px 0;
}
.perfil_comprarAssinaturas {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}
.perfil_comprarAssinaturas li img {
  width: 100%;
  cursor: pointer;
  /*border: solid 1px;*/
}
.perfil_comprarAssinaturas li img:hover {
  opacity: 0.5;
}
.perfil_comprarAssinaturas li img:active {
  opacity: 1;
}
.contact_icon {
  margin: 0 15px;
}
.perfil_container {
  width: 100%;
}
.perfil_container h3 {
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  margin: 10px 0;
}
.perfil_contacts {
  font-family: 'Lato', sans-serif;
  text-align: left;
  margin: 5px 0;
}

.perfil_contacts a:hover {
  opacity: 0.5;
}
.perfil_contacts a:active {
  opacity: 1;
}
.userHeader {
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: #0080cc;
  text-align: left;
  margin: 20px 0;
}
label p { 
  font-family: 'Lato', sans-serif;
  text-align: left;
  margin-bottom: 5px;
}
.containerForm {
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.previewFile a {
  text-align: left;
  margin: 10px 0;
  display: block;
  font-size: 0.8rem;
  text-decoration: underline;
  color: #0080cc;
  transition: color 0.5s ease;
}
.previewFile a:hover {
  color: #000
}
.profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.foto-perfil {
  position: relative;
  cursor: pointer;
  text-align: center;
}
.foto {
  width: 100px;
  height: 120px;
  border: 2px solid #707070;
  background-color: #a5a5a5;
  overflow: hidden;
  transition: border-color 0.5s ease;
}
.foto:hover {
  border-color: #0080cc;
}
.foto-content{
  width: 100%;
  height: 100%;
  display: inline-block;
}
.foto input[type="file"]{
  cursor: pointer;
  width: 100%;
  height: 100%;
  display:block;
  position: absolute;
  opacity: 0 !important;
  top: 0;
  left:0;
}
.foto-img {
  width:100px;
  height: 120px;
  object-fit: cover;
}
.foto-add{
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 98px;
  height:118px;
  opacity: 0;
  background-color: rgba(0,0,0, 0);
  transition: all ease-in-out .3s;
}
.foto-add:hover {
  opacity: 1;
  background-color: rgba(0,0,0, .5);
}
.foto-add > span {
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bolder;
  font-family: 'Lato', sans-serif;
  margin: 40% auto;
  padding: 0 20px;
}

@keyframes shakeNo{
  20%, 60%{
    transform: translateX(6px);
  }
  40%, 80%{
    transform: translate(-6px);
  }
}

#mensagemPopupQrcode {
  text-align: center;
}