$cor-base-txt: #fff;
$cor-base: #284A6F;
$cor-fundo-tranp: #000000CC;
$cor-principal: #f18e17;
$cor-secundary: #eead5e;
$cor-desable-txt: #707070;
$cor-cinza-medio: rgb(240, 240, 240);
$conteiner-form-width: 500px;

*{
   
    margin: 0;
    padding: 0;
    color: inherit;
    box-sizing: border-box;
    font-family: sans-serif;
    text-decoration: inherit;
}
li, ul{
    list-style: none;

}

.fadein{
    animation: fade .2s ease;
    animation-fill-mode: both;
}
@keyframes fade {

    from { opacity: 0; }
    to {opacity: 1;}
  }
  @keyframes squeeze {

    from { transform: scale(1); }
    to {transform: scale(0);}
  }
  @keyframes stretcher {

    from { transform: scale(0); }
    to {transform: scale(1);}
  }
  
a{
    cursor: pointer;
}
hr{
    border: none;
    border-bottom: 1px solid $cor-cinza-medio;
}

.warning{
    border: 2px solid red !important;
    //box-shadow: inset 0px 0px 5px crimson;
}
.warningPhone{
    border: 2px solid red !important;
    //box-shadow: inset 0px 0px 5px crimson;
}

.disable{
    display: none !important;
}
.disableAutocomplete{
    opacity:  0;
    position: absolute;
    top: 0;
    width: 0px;
    z-index: -99999999999;

}
.hide{
    visibility: hidden;
}

.spin {
    animation: spin 1s infinite;
    animation-timing-function: ease-in-out;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}